import React from 'react'
import PropTypes from 'prop-types'

import InfiniteScrollStyled from './styled'

class InfiniteScroll extends React.PureComponent {
  componentDidMount() {
    this.content.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this.content.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target
    /* scrollTop might return decimal if use display scale and scrollHeight will return round up integer */
    const isBottom = Math.ceil(scrollTop) + offsetHeight === scrollHeight
    if (isBottom && this.props.onScrollCall) {
      this.props.onScrollCall()
    }
  }

  handleMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave()
    }
  }

  render() {
    return (
      <InfiniteScrollStyled
        id={this.props.id}
        data-test={this.props.id}
        ref={ref => {
          this.content = ref
        }}
        style={this.props.style}
        className={this.props.className}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.children}
      </InfiniteScrollStyled>
    )
  }
}

InfiniteScroll.defaultProps = {
  id: 'infinite-scroll',
  className: '',
  style: {},
}

InfiniteScroll.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]).isRequired,
  onScrollCall: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default InfiniteScroll
